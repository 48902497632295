import * as React from "react";
import type { HeadFC } from "gatsby";
import styled from "styled-components";

import { Container } from "../components/Grid";
import { Layout } from "../components/Layout";
import { LegalDocs } from "./privacy-policy";

const ScMain = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #020203;

  .post-full-content {
    background-color: transparent;
  }
`;

const LegalPage = () => {
  return (
    <Layout>
      <ScMain>
        <Container>
          <LegalDocs />
        </Container>
      </ScMain>
    </Layout>
  );
};

export default LegalPage;

export const Head: HeadFC = () => <title>Contact Us</title>;
